@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

#root {
  font-family: 'Poppins', sans-serif;
}

/* .loader-container {
  display: flex;
  justify-content: center; 
  align-items: center; 
  height: 100vh; 
} */
